import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled/macro'
import { Text } from '../components/common/typography'
import bp from '../components/common/breakpoints'
import ClutchWidget from '../components/clutchWidget'
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  GREY_COLOR,
  SECONDARY_COLOR,
  WHITE_COLOR,
} from './common/color'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import ModalService from './ServiceModal/ModalService'

import DevelopmentSVG from '../images/majesticons/monitor.svg'
import AgileSVG from '../images/majesticons/rocket-3-start.svg'
import UISVG from '../images/majesticons/edit-pen-4.svg'
import StrategicSVG from '../images/majesticons/presentation-chart.svg'
import CreativeSVG from '../images/majesticons/flask.svg'
import StartupSVG from '../images/majesticons/briefcase.svg'
import SalesforceSVG from '../images/majesticons/cloud.svg'
import Web3SVG from '../images/majesticons/etherium-circle.svg'
import ManufacturingSVG from '../images/majesticons/community.svg'
import MiningSVG from '../images/majesticons/code-block.svg'
import DevopsSVG from '../images/majesticons/cloud.svg'
import RecruitmentSVG from '../images/majesticons/users.svg'
import RefreshSVG from '../images/majesticons/reload.svg'
import { Button } from 'react-bootstrap'
import { display } from 'styled-system'
/*
import {
  STRATEGICCONSULTING,
  STARTUP,
  SOFTWAREDEVELOPMENT,
  MINING,
  CREATIVE,
  SALESFORCE,
  UIUX,
} from './common/images'
*/

const services = [
  {
    number: <DevelopmentSVG />,
    title: 'Software Development',
    description:
      'We build mobile, web, cloud apps for your business and guide you with our batch of experts to take the best and fastest path towards monetization for your application/business.',
    longdescription: `Software development consulting is a type of consulting that helps businesses build high-quality software applications that meet their unique needs and requirements. At its core, software development consulting is about helping businesses leverage technology to achieve their goals and stay ahead of the competition.
    <br><br>
    One of the key areas of focus for software development consulting is mobile, web, and cloud app development.
    <br><br>
    Our team of experts includes skilled software developers, designers, and project managers who work closely with clients to understand their unique needs and requirements.
    <br><br>
    Ultimately, our goal as a software development consulting firm is to help businesses build high-quality mobile, web, and cloud apps that meet their unique needs and requirements, and to guide them towards the fastest and most effective path to monetization. By leveraging our team of experts and our deep understanding of the industry, we can help businesses succeed in the highly competitive digital landscape and achieve their goals.
    `,
    path: 'development',
    tags: ['software', 'software2', 'crm'],
  },
  {
    number: <AgileSVG />,
    title: 'Agile Project Management',
    description: 'We are fans of Agility and practice what we preach.',
    longdescription: `Agile project management is a methodology used in software development that emphasizes flexibility, collaboration, and iterative development. Instead of using a traditional, linear project management approach, Agile project management is based on the principles of the Agile Manifesto, which prioritize delivering value to customers, responding to change, and working collaboratively as a team. The Agile approach involves breaking down a project into small, manageable chunks called sprints.
    <br><br>
    The benefits of Agile project management include: <br>
    1. Increased flexibility: Agile project management allows for changes to be made mid-project, based on feedback from stakeholders or changes in market conditions. <br>
    2. Increased collaboration: Agile project management emphasizes collaboration between team members, stakeholders, and customers, resulting in better communication and understanding of project requirements. <br>
    3. Faster time to market: Because Agile project management emphasizes iterative development and constant feedback, projects can be completed more quickly than traditional linear approaches. <br>
    4. Improved quality: The iterative nature of Agile project management allows for constant testing and evaluation, resulting in a higher-quality product. <br>
    5. Better stakeholder satisfaction: By involving stakeholders in the development process and prioritizing their feedback, Agile project management can result in a product that better meets their needs and expectations. <br>
    <br>
    Overall, Agile project management is a highly effective methodology for software development projects, as it allows for flexibility, collaboration, and iterative development, resulting in faster time to market, improved quality, and better stakeholder satisfaction.
    `,
    path: 'agile',
    tags: ['software'],
  },
  {
    number: <UISVG />,
    title: 'UX/UI Design',
    description:
      'Understanding your Customer Journey is essential to defining the desired Flow and User Experience',
    longdescription: `UX/UI design is the process of creating the visual and interactive elements of a digital product, such as a website or mobile application, to optimize the user experience. A great UX/UI design ensures that the product is intuitive and easy to use, while also creating an aesthetically pleasing and engaging interface that encourages users to engage with the product. One of the key goals of UX/UI design is to create a seamless and enjoyable user experience that helps users achieve their goals and accomplish their tasks quickly and easily. This involves understanding user behavior and needs, and using this information to design an interface that is intuitive and easy to use.
    <br><br>
    To achieve this, our UX/UI designers work closely with you by:<br>
        - Conducting research, such as user testing and interviews<br>
        - Creating a visual design that is aesthetically pleasing and engaging for users<br>
    <br><br>
    Overall, UX/UI design is critical for creating a positive user experience that encourages engagement and drives business growth. A well-designed user interface can help users achieve their goals quickly and easily, while also creating a memorable and enjoyable experience that encourages them to return to the product. If you're looking to optimize the user experience of your digital product, working with a UX/UI design team can help ensure that your product is intuitive, engaging, and optimized for success.
    `,
    path: 'design',
    tags: ['ui'],
  },
  {
    number: <StrategicSVG />,
    title: 'Strategic Consulting',
    description:
      'We help you identify opportunities for improvement and with our engineering expertise help come up with tailor made solutions.',
    longdescription: `Strategic consulting is a type of consulting that helps organizations improve their overall performance and achieve their goals by identifying areas of improvement and creating custom solutions to address them. These improvements can range from increasing efficiency and productivity to improving customer satisfaction and boosting revenue.
    <br><br>
    Let us help by:<br>
        - Analyzing your IT operations<br>
        - Identify areas of weakness and inefficency<br>
    <br><br>
    Once Identified, our consultants an use their expertise and knowledge to develop custom solutions that address these issues and help the organization achieve its goals.
    <br><br>
    One of the key benefits of strategic consulting is the ability to tailor solutions to meet the unique needs of each organization.
    <br><br>
    Because every organization is different, there is no one-size-fits-all solution to improving performance.
    `,
    path: 'consulting',
    tags: ['support'],
  },
  {
    number: <CreativeSVG />,
    title: 'Creative Solutions',
    description:
      'From UI design, graphics, animation, AR/VR, we use our skills to fulfill your business vision to attract more customers/business.',
    longdescription: `Creative solutions practice is all about using design and creativity to solve business problems and achieve business objectives. This can range from creating stunning graphics and animations for a website or social media campaign to developing immersive AR/VR experiences that engage and captivate customers.
    <br><br>
    As a creative solutions firm, our team of experts specializes in a wide range of creative disciplines, including UI design, graphics, animation, and AR/VR development. We work closely with clients to understand their unique needs and goals, and use our skills and expertise to develop tailor-made solutions that help them achieve their vision and attract more customers and business.
    <br><br>
    Our key areas of focus related to creative solutions practice include:
    <br>
       - UI Design<br>
       - Graphics and Animation<br>
       - AR/VR development<br>
    <br><br>
    Overall, our creative solutions practice is focused on helping businesses achieve their goals by using design and creativity to solve problems and attract more customers and business. With our range of skills and expertise, we can develop tailor-made solutions that are optimized for engagement, conversion, and growth.
    `,
    path: 'solutions',
    tags: ['support'],
  },
  {
    number: <StartupSVG />,
    title: 'Startup Tech Partner',
    description:
      'Let us be part of the Magic! We can augment your Tech staff be it Software Devs, QA Devs, DevOps,etc to help you build the future.',
    longdescription: `As a startup tech partner, one of the main things we offer is the ability to augment your existing tech staff with additional resources that can help you build the future.
    <br><br>
    For example we can work providing:<br>
        - Software developers to help build your project<br>
        - QA developers to ensure that your product is high-quality and bug-free<br>
        - DevOps professionals to help you deploy and manage your infrastructure<br>
        - Data engineers, DBA, Blockchain devs, etc
    <br><br>
    By augmenting your tech staff with additional resources, you can benefit in a number of ways:<br>
        - Accelerate pace of development<br>
        - Increase the quality and reliability of your product(s)<br>
        - Free up existing staff to focus on higher-level tasks and strategic initatives
    <br><br>
    Whether you need additional software developers, QA developers, DevOps professionals, or other resources, we can provide the talent you need to achieve your goals and succeed in the highly competitive startup landscape.
    `,
    path: 'partner',
    tags: ['support'],
  },
  {
    number: <SalesforceSVG />,
    title: 'Salesforce Consulting',
    description:
      'Our team of certified Salesforce administrators, developers, and experienced project management (SCRUM) and QA process teams can be part of your team!',
    longdescription: `As a Salesforce consulting firm, our team of certified administrators and developers has extensive experience working with clients across a range of industries to implement and customize Salesforce solutions. We work closely with clients to understand their business requirements, and use our expertise to develop tailor-made solutions that meet their needs and drive growth.
    <br><br>
    Our team of Salesforce administrators has deep knowledge of the platform and its capabilities, and can help clients configure and customize Salesforce to meet their unique business needs. We can also provide ongoing support and maintenance to ensure that Salesforce is always running smoothly and delivering value to the business.
    <br><br>
    Our team of Salesforce developers specializes in custom development on the platform, and can help clients build custom apps, integrations, and workflows that extend the capabilities of Salesforce and improve business processes.
    <br><br>
    Overall, our Salesforce consulting practice is designed to help businesses fully realize the benefits of Salesforce by providing expert guidance and support. Whether you need help configuring and customizing Salesforce, developing custom solutions, or managing a Salesforce project from start to finish, our team of certified Salesforce administrators, developers, and experienced project management and QA process teams can be part of your team and help drive growth for your business.
    <br><br>
    Please check our use case for Higher Education industry here: https://www.alioit.com/case-studies
    <br><br>
    Find us at the AppExchange <a href='https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=a0N4V00000HZUu2UAH&legacy=true' style='
    text-decoration:underline;'
    >here</a>
    `,
    path: 'salesforce',
    tags: ['crm'],
  },
  {
    number: <Web3SVG />,
    title: 'Web3 Development',
    description:
      'We have a team of Blockchain Developers focusing on Web3 with the following set of tools: Solidity for Smart contracts, OpenZepellin, PolygonSDK, Ether.js for front end and more.',
    longdescription: `Through comprehensive consultation, development, and implementation processes, we offer:
    <br><br>  
    Web3 development has the potential to revolutionize the way we interact with the internet, by enabling peer-to-peer interactions and creating decentralized and secure networks that are not controlled by any single entity. This has numerous implications, ranging from the creation of decentralized marketplaces and social networks to the development of new financial systems and voting mechanisms.
    <br><br>
    Some of the key technologies and platforms used in Web3 development include Ethereum, Solidity (the programming language used to write smart contracts), IPFS, and various development frameworks such as Truffle and Embark.
    <br><br>
    Check out our internal Web3 project <a href='https://sbn.finance' style='text-decoration:underline;'>here</a>`,
    path: 'web3',
    tags: ['web3'],
  },
  {
    number: <ManufacturingSVG />,
    title: 'Manufacturing Solutions',
    description: 'Bring your business to the next era in digitalization.',
    longdescription: `At Alio IT, we offer cutting-edge software solutions for manufacturing and mining operations. Our software is designed to optimize processes, increase efficiency, and reduce costs across a range of industries, from automotive to mining to oil and gas.
    <br><br>
    Our manufacturing solutions include developing software for inventory management, production planning and scheduling, quality control, and supply chain management. Our software can help you streamline your operations, reduce waste, and improve product quality, while giving you greater visibility and control over your processes.
    <br><br>
    Our PLM Software solution <a href='https://www.operoute.com/' style='text-decoration:underline;'>OPEROUTE</a> helps manufacturing companies who want to better track their product in the shop floor by providing clear and concise data to users so they can follow the right and approved operating procedures and work instructions and visuals to feed into their work orders to further increase quality of what is being produced. Visit www.operoute.com for more information.
    <br><br>
    In addition to our software solutions, we offer exceptional customer support and training to ensure that you get the most out of our products. Our team of experts is always available to provide guidance and support, and we are dedicated to building long-term relationships with our clients based on trust and mutual success.
    <br><br>
    At Alio IT, we are committed to helping our clients achieve their goals through innovative software solutions that drive success and growth.`,
    path: 'manufacturing',
    tags: ['prebuild'],
  },
  {
    number: <MiningSVG />,
    title: 'Artificial Intelligence',
    description: 'Bring your business to the next era with Artificial Intelligence.',
    longdescription: `<ol>
    <li>Custom AI Solutions: Crafting bespoke AI solutions designed to optimize processes, enhance decision-making, and drive business growth.</li>
    <li>Data Analysis and Modeling: Utilizing advanced data analytics and machine learning techniques to extract valuable insights from large datasets, enabling informed decision-making.</li>
    <li>Natural Language Processing (NLP): Developing NLP applications to understand, interpret, and generate human language, facilitating improved customer interactions, sentiment analysis, and content understanding.</li>
  </ol>`,
    path: 'insuretech',
    tags: ['web3'],
  },
  {
    number: <DevopsSVG />,
    title: 'DevOps Consulting',
    description:
      'At Alio we pride ourselves in our DevOps experience in AWS, Azure and GCP. We have certified staff that can help you with your CI/CD setup as well infrastructure setup.',
    longdescription: `At Alio we pride ourselves in our DevOps experience in AWS, Azure and GCP. We have certified staff that can help you with your CI/CD setup as well infrastructure setup using:
    <br><br>
    - Kubernetes <br>
    - Docker <br>
    - Github Actions <br>
    - Gitlab CI/CD <br>
    - Bitbucket CI/CD <br>
    - AWS CodeBuild, CodePipeline <br>
    - AWS EKS, ECS <br>
    - Continous Testing <br>
    - etc
    <br><br>
    Let us help your team automate and streamline your development cycles with faster feedback using CI/CD, Continous testing and deployments.
    <br><br>
    Feel free to use our open source Infrastructure to Code app Cedar <a href='https://www.cedardeployer.io/' style='text-decoration:underline;'>here</a>
    `,
    path: 'devops',
    tags: ['software2'],
  },
  {
    number: <RecruitmentSVG />,
    title: 'Recruitment Services',
    description:
      'Software requires reliability, efficiency, security and maintainability, and a solid software development team to achieve this. One of the biggest challenges for tech companies is to hire and retain top developers. We can take care of this.',
    longdescription: `Software requires reliability, efficiency, security and maintainability, and a solid software development team to achieve this. One of the biggest challenges for tech companies is to hire and retain top developers. We can take care of this.
    <br><br>
    This is how we do it: <br>
    - Recruitment services for you to hire remotely in US or LATAM. <br>
    - Staff Augmentation (An extended part of your team, but we take care of the admin and payroll). <br>
    - Contract Talent Solution (You can hire developers for short term projects for 3, 6, 9 or 12 months contracts). <br>
    - Building of the complete software solution (Includes project management and development).
    `,
    path: 'recruitment',
    tags: ['software2'],
  },
]

const Wrapper = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: transparent;
  ${bp[3]} {
  }
`

const ContainerWrap = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: space-between;
  }
`

const ContainerSelect = styled(motion.div)`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 4rem;
  padding-right: 5rem;
`

const Container = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  row-gap: 0rem;
  column-gap: 0rem;
  align-items: center;
  justify-items: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 4rem;

  ${bp[1]} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Card = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;
  min-height: 200px;
  width: 100%;
  padding: 1.5rem;
  padding-top: 1rem;
  background: ${GREY_COLOR};
  box-sizing: border-box;
  border-radius: 0px;
  border: 1px solid #afafaf;
  height: fit-content;
  position: relative;
  ${bp[2]} {
    grid-template-rows: 2fr auto 3fr;
    min-width: unset;
    // min-height: 40vh;
    // height: 40vh;
  }
  @media (max-width: 767px) {
    min-height: auto;
  }
  &:hover {
    background: ${SECONDARY_COLOR};
    transition: 0.3s ease-out;
  }

  &.highlight {
    background: ${SECONDARY_COLOR};
    transition: 0.3s ease-out;
  }
`
const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  ${bp[2]} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`
const CardNumber = styled.div`
  margin: 0;
  font-family: 'Manrope';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
  padding-bottom: 6px;
  width: 35px;
  color: ${BLACK_COLOR};
  ${Card}:hover & {
    color: ${PRIMARY_COLOR};
  }
  ${Card}.highlight & {
    color: ${PRIMARY_COLOR};
  }
`
const CardTitle = styled.h4`
  margin: 0;
  // font-family: 'source code pro';
  // text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2rem;
  color: ${BLACK_COLOR};
  // opacity: 0.8;
  ${Card}:hover & {
    color: ${WHITE_COLOR};
  }
  ${Card}.highlight & {
    color: ${WHITE_COLOR};
  }
`
const WizardBody = styled(motion.div)``
const WizardContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`
const WizardTitle = styled(motion.h3)`
  font-size: 1.2rem;
`

const WizardButton = styled(motion.button)`
  background: transparent;
  border-color: gray;
  color: #000;
  margin: 6px;
  padding: 16px;
  &:hover {
    background: ${GREY_COLOR};
    transition: 0.3s ease-out;
  }

  &.selected {
    background: ${BLACK_COLOR};
    color: #fff;
  }
`

const CardDescription = styled(Text)`
  font-weight: 400;
  color: ${BLACK_COLOR};
  opacity: 0.4;
  font-size: 1rem;
  display: none;
  font-family: 'Manrope';
  margin-bottom: 10%;
  ${bp[1]} {
    font-size: 0.75rem;
    display: unset;
  }
`
const HeadingWhite = styled(motion.h1)`
  font-size: 2.5rem;
  width: 100%;
  font-family: 'Manrope' !important;
  color: ${BLACK_COLOR};
  font-weight: 700;
  margin-bottom: 3rem;
  @media (max-width: 812px) {
    font-size: 2.5rem;
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
  ${bp[3]} {
    font-size: 4rem;
  }
`
const CareerDetailsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
`

const WizardReset = styled.button`
  align-items: center;
  margin-top: 30px;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  color: ${BLACK_COLOR};
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  svg {
    width: 34px;
    color: ${PRIMARY_COLOR};
    padding-right: 10px;
  }
`

export const ButtonNoStyle = styled.button`
  display: none;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  color: ${BLACK_COLOR};
  bottom: 20px;
  ${bp[2]} {
    font-size: 12px;
    padding: 0px 16px;
  }
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${Card}:hover & {
    color: ${PRIMARY_COLOR};
    display: inline-flex;
  }
  ${Card}.highlight & {
    color: ${PRIMARY_COLOR};
    display: inline-flex;
  }
`
const CardAnimate = {
  offscreen: { x: -10, opacity: 0 },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 1,
    },
  },
}

const ServicesLanding = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isWiz1Visible, setIsWiz1Visible] = useState(true)
  const [isWiz2Visible, setIsWiz2Visible] = useState(false)
  const [isWiz3Visible, setIsWiz3Visible] = useState(false)
  const [wiz1Value, setWiz1Value] = useState(null)
  const [wiz2Value, setWiz2Value] = useState(null)
  const [selectedButton, setSelectedButton] = useState(null)
  const elementRef = useRef(null)

  const handleItemClick = (item, index) => {
    setSelectedItem(item)
    setSelectedIndex(index)
    setShow(true)
  }

  const handleSelect = (selection) => {
    if (selection == 'startup') {
      setIsWiz1Visible(false)
      setIsWiz2Visible(true)

      setSelectedButton(null)
      setWiz2Value(null)
    } else if (selection == 'company') {
      setIsWiz1Visible(false)
      setIsWiz3Visible(true)

      setSelectedButton(null)
      setWiz2Value(null)
    } else {
      console.log('selection-handleSelect', selection)
      console.log('wiz1Value-handleSelect', wiz1Value)
      setSelectedButton(selection)
      setWiz2Value(selection)
      elementRef.current.focus()
    }

    setWiz1Value(selection)
  }

  const handleSelect2 = (selection) => {
    console.log('selection-handleSelect2', selection)
    console.log('wiz2Value-handleSelect2', wiz2Value)
    setSelectedButton(selection)
    setWiz2Value(selection)
    elementRef.current.focus()
  }

  const handleReset = () => {
    setWiz1Value(null)
    setWiz2Value(null)
    setIsWiz2Visible(false)
    setIsWiz3Visible(false)
    setIsWiz1Visible(true)
    setSelectedButton(null)
  }

  const isButtonSelected = (selection) => selectedButton === selection

  useEffect(() => {
    if (inView) {
      controls.start('onscreen')
    }
  }, [controls, inView])
  return (
    <Wrapper id="services">
      {selectedItem && (
        <ModalService
          item={selectedItem}
          index={selectedIndex}
          services={services}
          show={show}
          setShow={setShow}
        />
      )}
      {' '}
      <ContainerWrap>
        <ContainerSelect>
          <HeadingWhite
            variants={CardAnimate}
            initial={'offscreen'}
            ref={ref}
            animate={controls}
            className="typewriter4"
          >
            What we offer
          </HeadingWhite>
          {isWiz1Visible && (
            <WizardBody id="wiz-1">
              <WizardTitle id="whoAreYou">Who are you?</WizardTitle>
              <hr class="solid" />
              <WizardContainer id="wizardsContainer">
                <WizardButton onClick={() => handleSelect('startup')}>
                  I'm a startup
                </WizardButton>
                <WizardButton onClick={() => handleSelect('company')}>
                  I'm a company in growth phase
                </WizardButton>
                <WizardButton
                  onClick={() => handleSelect('web3')}
                  className={isButtonSelected('web3') ? 'selected' : ''}
                >
                  I'm building AI or web3 apps
                </WizardButton>
                <WizardButton
                  onClick={() => handleSelect('prebuild')}
                  className={isButtonSelected('prebuild') ? 'selected' : ''}
                >
                  I'm looking for pre-built solutions
                </WizardButton>
              </WizardContainer>
            </WizardBody>
          )}
          {isWiz2Visible && (
            <WizardBody id="wiz-2">
              <WizardTitle>Have a goal in mind?</WizardTitle>
              <hr class="solid" />
              <WizardContainer>
                <WizardButton
                  onClick={() => handleSelect2('software')}
                  className={isButtonSelected('software') ? 'selected' : ''}
                >
                  Increase my software development capabilities
                </WizardButton>
                <WizardButton
                  onClick={() => handleSelect2('ui')}
                  className={isButtonSelected('ui') ? 'selected' : ''}
                >
                  Build/Improve a fresh user interface and experience
                </WizardButton>
                <WizardButton
                  onClick={() => handleSelect2('support')}
                  className={isButtonSelected('support') ? 'selected' : ''}
                >
                  Looking for guidance and support defining and designing my
                  next big product
                </WizardButton>
              </WizardContainer>
              <WizardReset onClick={() => handleReset()}>
                <RefreshSVG /> Reset
              </WizardReset>
            </WizardBody>
          )}
          <ClutchWidget />
          {isWiz3Visible && (
            <WizardBody id="wiz-3">
              <WizardTitle>Are you a company in growth phase?</WizardTitle>
              <hr class="solid" />
              <WizardContainer>
                <WizardButton
                  onClick={() => handleSelect2('software2')}
                  className={isButtonSelected('software2') ? 'selected' : ''}
                >
                  I'm looking to optimize and improve my software solutions
                </WizardButton>
                <WizardButton
                  onClick={() => handleSelect2('crm')}
                  className={isButtonSelected('crm') ? 'selected' : ''}
                >
                  I need help customizing, building and/or implementing my CRM
                  solution
                </WizardButton>
              </WizardContainer>
              <WizardReset onClick={() => handleReset()}>
                <RefreshSVG /> Reset
              </WizardReset>
            </WizardBody>
          )}
        </ContainerSelect>
        <div ref={elementRef} tabIndex={-1}>
          <Container
            initial={'offscreen'}
            ref={ref}
            animate={controls}
            transition={{ staggerChildren: 0.1 }}
            id='servicesContainer'
          >
            {services.map((service, index) => (
              <Card
                variants={CardAnimate}
                key={service.title.toLocaleLowerCase().replace(' ', '-')}
                className={service.tags.includes(wiz2Value) ? 'highlight' : ''}
              >
                <CardHeader>
                  <CardNumber>{service.number}</CardNumber>
                  <CardTitle>{service.title}</CardTitle>
                </CardHeader>
                {/* <CardDescription>{service.description}</CardDescription> */}
                <CareerDetailsContainer>
                  <ButtonNoStyle
                    onClick={() => handleItemClick(service, index)}
                  >
                    See details
                  </ButtonNoStyle>
                </CareerDetailsContainer>
              </Card>
            ))}
          </Container>
        </div>
      </ContainerWrap>
    </Wrapper>
  )
}

export default ServicesLanding
